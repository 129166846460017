<template>
  <v-row class="d-flex justify-center">
    <v-col lg="10" xl="8">
      <v-sheet
        min-height="50vh"
        rounded="lg"
        class="pa-3"
      >
        <v-alert
          v-if="!user.cv.completo || id !== undefined"
          outlined
          dense
          border="left"
          type="info"
        >
          <div v-if="!user.cv.completo">Complete sus datos para armar su CV</div>
          <div v-else>Corrobore sus datos antes de postularse</div>
        </v-alert> 
        <v-form ref="form">

          <v-row>
            <v-col>
              <h1 class="text-h5 font-weight-light"><v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-user-tie</v-icon>Información personal</h1>
              <v-divider class="mb-4 mx-md-7"></v-divider>

              <v-row class="d-flex px-md-8 px-0">
                <v-col cols="6" md="3">
                  <v-text-field
                    v-model.trim="user.nombre"
                    :rules="[rules.required]"
                    label="Nombre/s"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    v-model.trim="user.apellido"
                    :rules="[rules.required]"
                    label="Apellido/s"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    v-model.trim="fecha_nacimiento"
                    :rules="[rules.required, rules.date]"
                    label="Fecha de nacimiento"
                    placeholder="dd/mm/aaaa"
                    v-mask="'##/##/####'"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    v-model.trim="user.cv.nacimiento.lugar"
                    :rules="[rules.required]"
                    label="Lugar de nacimiento"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex px-md-8 px-0">
                <v-col cols="12" md="8">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model.trim="user.cv.cuil"
                        :rules="[rules.required]"
                        label="CUIL"
                        v-mask="'##-########-#'"
                        @blur="autocomplete_dni"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-select
                        v-model="user.tipo_documento"
                        :rules="[rules.required]"
                        :items="tipos_documentos"
                        label="Tipo de documento"
                        validate-on-blur
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field
                        v-model.trim="user.documento"
                        :rules="[rules.required]"
                        label="Nº documento"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="4">
                      <v-select
                        v-model="user.cv.provincia"
                        :rules="[rules.required]"
                        :items="nombre_provincias"
                        label="Provincia"
                        @change="get_departamentos"
                        validate-on-blur
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="4">
                      <v-select
                        v-model="user.cv.departamento"
                        :rules="[rules.required]"
                        :items="departamentos"
                        label="Departamento"
                        validate-on-blur
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field
                        v-model.trim="user.cv.localidad"
                        :rules="[rules.required]"
                        label="Localidad"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="8">
                      <v-text-field
                        v-model.trim="user.cv.domicilio"
                        :rules="[rules.required]"
                        label="Domicilio actual"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field
                        v-model.trim="user.cv.telefono.celular"
                        :rules="[rules.required]"
                        label="Teléfono celular"
                        v-mask="'###############'"
                        validate-on-blur
                      >
                        <template v-slot:append>
                          <v-tooltip
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon small v-on="on">
                                fas fa-exclamation-circle
                              </v-icon>
                            </template>
                            Incluir el código de área sin el prefijo 15
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field
                        v-model.trim="user.cv.telefono.alternativo"
                        label="Teléfono alternativo"
                        v-mask="'###############'"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model.trim="user.email"
                        readonly
                        filled
                        label="Correo electrónico"
                        :append-icon="icon"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-center">
                  <v-dialog
                    v-if="$vuetify.breakpoint.xs"
                    v-model="foto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >Foto de perfil<v-icon right>fas fa-user-circle</v-icon></v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5 font-weight-light"></v-card-title>
                      <v-card-text>
                        <Foto :card="true" :usuario="user" />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <Foto v-else :usuario="user" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h1 class="d-flex text-h5 font-weight-light">
                <v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-graduation-cap</v-icon>
                Formación y conocimientos
                <Ayuda text="Registre todos los estudios alcanzados o en curso" />
              </h1>
              <v-divider class="mb-4 mx-md-8"></v-divider>
              <v-row class="px-4">
                <v-col cols="12" class="px-md-8 px-0">
                  <v-card>
                    <TablaFormacionAcademica :form_academica="user.cv.formacion" />
                  </v-card>
                </v-col>
                <v-col cols="12" class="mb-4 px-md-8 px-0">
                  <h1 class="d-flex text-h6 font-weight-light mb-1">
                    Otros conocimientos
                    <Ayuda text="Idiomas, conocimientos informáticos, cursos, oficios, etc." />
                  </h1>
                  <v-card>
                    <TablaOtrosConocimientos :otros_conocimientos="user.cv.conocimientos" />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="d-flex">
                <h1 class="text-h5 font-weight-light"><v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-briefcase</v-icon>Experiencia laboral</h1>
                <v-spacer></v-spacer>
                <v-checkbox
                  v-model="user.cv.sin_exp"
                  :disabled="user.cv.experiencia.length !== 0"
                  class="mr-md-9 mt-md-n1 mt-0"
                  label="Sin experiencia"
                  hide-details
                ></v-checkbox>
              </div>
              <v-divider class="mb-4 mx-md-8"></v-divider>
              <v-row class="px-4">
                <v-col cols="12" class="mb-4 px-md-8 px-0">
                  <v-card>
                    <TablaExperienciaLaboral :xp_laboral.sync="user.cv.experiencia" :disabled="user.cv.sin_exp" />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h1 class="d-flex text-h5 font-weight-light">
                <v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-folder-open</v-icon>
                Mis Archivos
                <Ayuda text="CV, certificados, carta de presentación, etc." />
              </h1>
              <v-divider class="mb-4 mx-md-8"></v-divider>
              <v-row class="px-4">
                <v-col cols="12" class="px-md-8 px-0">
                  <v-card>
                    <Archivos :usuario="user" />
                  </v-card>
                </v-col>
                <v-col cols="12" class="mb-4 px-md-8 px-0">
                  <h1 class="d-flex text-h6 font-weight-light mb-1">
                    Enlaces personales
                    <Ayuda text="LinkedIn, redes sociales, video de presentación, etc." />
                  </h1>
                  <v-card>
                    <Enlaces :usuario="user" />
                  </v-card>
                </v-col>
                <v-col cols="12"></v-col>
                <v-col cols="6" md="3">
                  <v-select
                    v-model.trim="user.cv.disp_horaria"
                    :items="disponibilidad_horaria"
                    label="Disponibilidad horaria"
                    append-icon="fas fa-clock"
                    class="pl-md-6"
                  ></v-select>
                </v-col>
                <v-col v-if="!$vuetify.breakpoint.xs" cols="1"></v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    v-model.trim="user.cv.pretension_salarial"
                    label="Pretensión salarial"
                    prefix="$"
                    v-mask="mask"
                    class="pl-md-6"
                  ></v-text-field>
                </v-col>
                <v-col v-if="!$vuetify.breakpoint.xs" cols="1"></v-col>
                <v-col cols="12" md="4" class="d-flex">
                  <v-icon left>fas fa-car</v-icon> 
                  <v-checkbox
                    v-model="user.cv.carnet_conducir"
                    label="Carnet de conducir"
                    class="ml-4"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-col class="d-md-flex justify-end text-right mt-4">
                <v-btn
                  v-if="id !== undefined"
                  color="error"
                  class="mb-2 mb-md-0"
                  to="/"
                >Cancelar</v-btn>
                <div class="ml-4">
                  <Confirmar :nombre="boton_texto" @action="save"/>
                </div>
              </v-col>
            </v-col>
          </v-row>

        </v-form>
      </v-sheet>
    </v-col>

    <v-snackbar
      v-model="snackbar.value"
      :timeout="snackbar.color === 'success' ? 6000 : 3000"
      class="body-1 mb-4"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <a v-if="snackbar.color === 'success'" href="/" class="white--text"> búsquedas activas</a>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.value = false"><v-icon>fas fa-times</v-icon></v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="dialog_value"
      width="400"
    >
      <v-card>
        <v-card-text class="pt-6">
          <v-alert
            outlined
            prominent
            :type="dialog.color"
          >
            {{ dialog.text }}
          </v-alert> 
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4 mt-n6 mr-2">
          <v-btn
            :color="dialog.color"
            @click="close"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth, db } from '../firebase'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import TablaExperienciaLaboral from '../components/tablas/TablaExperienciaLaboral'
import TablaFormacionAcademica from '../components/tablas/TablaFormacionAcademica'
import TablaOtrosConocimientos from '../components/tablas/TablaOtrosConocimientos'
import Confirmar from '../components/utils/Confirmar'
import Archivos from '../components/Archivos'
import Enlaces from '../components/Enlaces'
import Ayuda from '../components/utils/Ayuda'
import Foto from '../components/Foto'
import moment from 'moment'

const currencyMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '.',
  includeThousandsSeparator: true,
  allowNegative: false,
})

export default {
  data() {
    return {
      snackbar: {
        value: false,
        text: '',
        color: 'success',
      },
      id: '',
      boton_texto: 'Guardar',
      foto: false,
      dialog_value: false,
      dialog: {
        color: 'success',
        text: '¡Felicitaciones! Su postulación ha sido recibida exitosamente',
      },
      mask: currencyMask,
      fecha_nacimiento: '',
      icon: 'fas fa-envelope',
      nombre_provincias: [],
      departamentos: [],
      disponibilidad_horaria: ['Full Time', 'Part Time'],
      rules: {
        required: value => !!value || 'Campo requerido',
        date: value => moment(value, 'DD/MM/YYYY', true).isValid() || 'Fecha inválida'
      },
    }
  },
  async created() {
    this.provincias.forEach(provincia => {
      this.nombre_provincias.push(provincia.nombre)
    })
    this.fecha_nacimiento = this.formatDate(this.user.cv.nacimiento.fecha)
    this.id = this.$route.params.id
    if (this.id !== undefined) {
      this.boton_texto = 'Guardar y postularme'
    }
    if (this.user.tipo_cuenta === 'Google') this.icon = 'fab fa-google'
    this.get_departamentos()
  },
  components: {
    TablaExperienciaLaboral, TablaFormacionAcademica, TablaOtrosConocimientos, Confirmar, Archivos, Enlaces, Ayuda, Foto
  },
  watch: {
    dialog_value (val) {
      val || this.close()
    },
  },
  computed: {
    ...mapState(['user', 'provincias', 'tipos_documentos']),
  },
  methods: {
    ...mapActions(['update_user']),
    async save() {
      if (this.$refs.form.validate()) {
        if (this.user.cv.experiencia.length === 0 && !this.user.cv.sin_exp) {
          this.snackbar.value = true
          this.snackbar.text = 'Si no posee experiencia laboral marque la casilla "Sin experiencia"'
          this.snackbar.color = 'orange'
          return
        }
        if (this.user.foto === '') {
          this.snackbar.value = true
          this.snackbar.text = 'Suba una foto de perfil'
          this.snackbar.color = 'orange'
          return
        }
        if (this.parseDate(this.fecha_nacimiento) > moment().subtract(18, 'years').format('YYYY-MM-DD')) {
          this.snackbar.value = true
          this.snackbar.text = 'Debe ser mayor de edad para completar el registro'
          this.snackbar.color = 'orange'
          return
        }
        this.$store.state.loading_screen = true
        try {
          if (this.id === undefined) {
            await this.guardar()
          } else {
            await this.postularse()
          } 
        } catch (error) {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
        this.$store.state.loading_screen = false
      } else {
        this.snackbar.value = true
        this.snackbar.text = 'Complete los campos requeridos'
        this.snackbar.color = 'orange'
      }
    },
    async guardar() {
      let cv = Object.assign({}, this.user.cv)
      cv.completo = true
      cv.log.modificacion = new Date(Date.now()).toISOString().substr(0, 10)
      cv.nacimiento.fecha = this.parseDate(this.fecha_nacimiento)
      cv.cuil = cv.cuil.replaceAll('-', '')

      let data = {
        nombre: this.user.nombre.toUpperCase(),
        apellido: this.user.apellido.toUpperCase(),
        documento: this.user.documento.replaceAll('.', ''),
        tipo_documento: this.user.tipo_documento,
        cv: cv
      }

      await db.collection('usuarios').doc(this.user.id).update(data)
        .then(async () => {
          await this.cargar_documento()
          this.user.cv.completo = true
          this.update_user(this.user)
          this.snackbar.value = true
          this.snackbar.text = 'Los cambios se han guardado correctamente. Ahora podés postularte en'
          this.snackbar.color = 'success'
        })
        .catch((error) => {
          this.snackbar.value = true
          this.snackbar.text = error.message
          this.snackbar.color = 'error'
        })
    },
    async postularse() {
      await db.collection('convocatorias').doc(this.id).get()
      .then(async (doc) => {
        if (doc.exists) {
          let cv = Object.assign({}, this.user.cv)
          let postulaciones = [...this.user.postulaciones]

          cv.completo = true
          cv.log.modificacion = new Date(Date.now()).toISOString().substr(0, 10)
          cv.nacimiento.fecha = this.parseDate(this.fecha_nacimiento)
          cv.cuil = cv.cuil.replaceAll('-', '')
          postulaciones.push({
            convocatoria: this.id,
            estado: 'En proceso de selección',
            etapa: '',
            fecha_post: new Date(Date.now()).toISOString().substr(0, 10)
          })

          if (this.user.postulaciones.find((conv => conv.convocatoria === this.id && conv.estado !== 'Cancelada por el postulante'))) {
            this.dialog_value = true
            this.dialog.text = 'Usted ya se ha postulado a esta búsqueda'
            this.dialog.color = 'warning'
          } else if (!doc.data().activa) {
            this.dialog_value = true
            this.dialog.text = 'La búsqueda seleccionada ya no se encuentra disponible'
            this.dialog.color = 'warning'
          } else {

            await db.collection('convocatorias').doc(doc.id).collection('postulantes').doc(this.user.id).set({
              apellido: this.user.apellido.toUpperCase(),
              nombre: this.user.nombre.toUpperCase(),
              estado: true,
              visto: false,
              telefono: this.user.cv.telefono.celular,
              email: auth.currentUser.email,
              fecha: new Date(Date.now()).toISOString().substr(0, 10)
            })
              .then(async () => {
                let data = {
                  nombre: this.user.nombre.toUpperCase(),
                  apellido: this.user.apellido.toUpperCase(),
                  documento: this.user.documento.replaceAll('.', ''),
                  tipo_documento: this.user.tipo_documento,
                  cv: cv,
                  postulaciones: postulaciones
                }
                await db.collection('usuarios').doc(this.user.id).update(data)
                  .then(async () => {
                    await this.cargar_documento()
                    this.user.cv.completo = true
                    this.user.postulaciones.push({
                      convocatoria: this.id,
                      estado: 'En proceso de selección',
                      etapa: '',
                      fecha_post: new Date(Date.now()).toISOString().substr(0, 10)
                    })
                    this.update_user(this.user)
                    this.dialog_value = true
                  })
                  .catch((error) => {
                    this.dialog_value = true
                    this.dialog.text = error.message
                    this.dialog.color = 'error'
                  })
              })
              .catch((error) => {
                this.dialog_value = true
                this.dialog.text = error.message
                this.dialog.color = 'error'
              })
          }
        } else {
          this.dialog_value = true
          this.dialog.text = 'No se ha encontrado la búsqueda seleccionada'
          this.dialog.color = 'error'
        }
      }).catch((error) => {
        this.dialog_value = true
        this.dialog.text = error.message
        this.dialog.color = 'error'
      })
    },
    async cargar_documento() {
      await db.collection('usuarios_registrados').doc(this.user.id).set({
        documento: this.user.documento.replaceAll('.', ''),
        email: auth.currentUser.email
      })
        .then(() => {
          this.user.documento = this.user.documento.replaceAll('.', '')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async close() {
      if(this.dialog.color === 'error') {
        await this.$router.push('/')
      } else {
        await this.$router.push('/postulaciones')
      }
      this.dialog_value = false
      this.dialog.text = '¡Felicitaciones! Su postulación ha sido recibida exitosamente'
      this.dialog.color = 'success'
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    autocomplete_dni () {
      if (this.user.documento === '') {
        this.user.documento = this.user.cv.cuil.replaceAll('-', '').substr(2, 8)
      }
    },
    get_departamentos () {
      this.departamentos = []
      if (this.user.cv.provincia) {
        this.departamentos = this.provincias.find(prov => prov.nombre === this.user.cv.provincia).departamentos
      }
    }
  }
}
</script>