<template>
    <v-tooltip
      :bottom="$vuetify.breakpoint.xs"
      :right="!$vuetify.breakpoint.xs"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          right
          small
          v-bind="attrs"
          v-on="on"
        >
          far fa-question-circle
        </v-icon>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>